import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateUtils} from './_shared/translate-utils';
import {AppConstants, AppRoles} from './app.constants';
import {faBars, faBell, faChevronRight, faSignOutAlt, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {BaseAuthRequest} from '@azure/msal-common';
import {
    AlarmService,
    AlertService, DocumentService,
    PushRegistrationService,
    UserService
} from './_services/configuration-services';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {AuthError, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {filter, takeUntil} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {CustomerConfigService} from './_shared/customer-config-service';
import {RolesService} from './_shared/roles-service';
import firebase from 'firebase/compat/app';
import 'firebase/messaging'
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {NotificationsService} from './_shared/notifications.service';
import {ConfirmModalService} from './_shared/_components/confirm-modal/confirm-modal.component';
import {CommunicationChannelConfigService} from './_shared/communication-channel-config.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PushNotificationModalComponent} from './_shared/_components/push-notification-modal/push-notification-modal.component';
import {Router} from '@angular/router';
import MessagePayload = firebase.messaging.MessagePayload;
import {SwUpdate} from '@angular/service-worker';
import {TranslateService} from '@ngx-translate/core';
import * as fileSaver from 'file-saver';
import {NGXLogger} from 'ngx-logger';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private alarmRefreshInterval: number;

    icons = {
        chevronRight: faChevronRight,
        signOut: faSignOutAlt,
        cross: faTimes,
        menu: faBars,
        alertNotification: faBell
    };

    AppRoles = AppRoles;

    env = environment;

    isIframe = false;
    isExpanded = false;
    isMobile = false;
    loggedIn = false;
    isAlarmTriggering = false;
    isAlertTriggering = false;
    hasUnreadMessage = false;

    envName = '';
    userDisplayName = '';
    userEmail: string = null; // Used for push notifications

    innerWidth = 0;
    maxWidthAllowed = 993;

    currentLanguage = TranslateUtils.defaultLanguage;

    private customerConfigLoadedSubscription: Subscription;
    private hasUnreadMessageSubscription: Subscription;
    private pushMessagesSubscription: Subscription;
    private loggedInSubscription: Subscription;

    private loggedInSubject$ = new BehaviorSubject(false);
    private loggedInMessage = this.loggedInSubject$.asObservable();

    private readonly destroying$ = new Subject<void>();

    constructor(private readonly translateUtils: TranslateUtils,
                @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
                private readonly authService: MsalService,
                private readonly msalBroadcastService: MsalBroadcastService,
                private readonly userService: UserService,
                public readonly customerConfigService: CustomerConfigService,
                public readonly communicationChannelConfigService: CommunicationChannelConfigService,
                private readonly alarmService: AlarmService,
                private readonly alertService: AlertService,
                private readonly rolesService: RolesService,
                private readonly translateService: TranslateService,
                private readonly notificationService: NotificationsService,
                private readonly pushRegistrationService: PushRegistrationService,
                private readonly modalService: NgbModal,
                private readonly router: Router,
                private readonly firebaseMessaging: AngularFireMessaging,
                private readonly updates: SwUpdate,
                private readonly documentService: DocumentService,
                private readonly log: NGXLogger) {
    }

    async ngOnInit(): Promise<void> {
        this.innerWidth = window.innerWidth;
        this.isMobile = this.innerWidth < this.maxWidthAllowed;
        this.isIframe = window !== window.parent && !window.opener;
        this.envName = environment.envName;
        this.currentLanguage = this.translateUtils.getLanguage();

        this.manageAppUpdate();

        this.hasUnreadMessageSubscription = this.communicationChannelConfigService.unreadMessage.subscribe(hasUnread => {
            if (this.loggedIn) {
                this.hasUnreadMessage = hasUnread;
            }
        });

        this.loggedInSubscription = this.loggedInMessage.subscribe(loggedIn => {
            if (this.loggedIn !== loggedIn) { // Handle state changes
                this.loggedIn = loggedIn;

                this.clearAlarmPolling();

                if (loggedIn) {
                    this.userService.getMe().pipe().subscribe(user => {
                        this.userDisplayName = user ? `${user.firstName} ${user.lastName}` : null;
                        this.userEmail = user?.email ?? null;

                        this.communicationChannelConfigService.fetchContactPageAccess();
                        this.communicationChannelConfigService.fetchHasUnreadMessage();
                        this.fetchHasAlarmOrAlert();
                        this.configureAlarmPolling();
                    });
                }
            }
        });

        this.checkIfLoggedIn();

        // Handle B2C redirects
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {
                this.checkIfLoggedIn();
                this.managePushSubscription();
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => {
                    return msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE;
                }),
                takeUntil(this.destroying$)
            )
            .subscribe((result: EventMessage) => {
                if (result.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                    // Clear B2C items from local storage to prevent unexpeced live lock
                    for (let i = 0; i < localStorage.length; i++) {
                        if (localStorage.getItem(localStorage.key(i)).indexOf('b2clogin') > 0) {
                            localStorage.removeItem(localStorage.key(i));
                        }
                    }

                } else {
                    const authError = result.error as AuthError;
                    // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
                    if (authError.message.includes('AADB2C90118')) { // Reset Password flow
                        // login request with reset authority
                        const resetPasswordFlowRequest: any = {
                            authority: `https://${environment.b2cDomainName}.b2clogin.com/${environment.b2cDomainName}.onmicrosoft.com/${environment.b2cResetPasswordPolicy}`,
                        };
                        this.login(resetPasswordFlowRequest);
                    }
                }
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.INITIALIZE_END),
                takeUntil(this.destroying$)
            )
            .subscribe(async () => {

                // Fix VNSG-3329 issue if the token has expired - refresh the token if idTokenClaims is not defined
                const accounts = this.authService.instance.getAllAccounts();

                if (accounts.length > 0) {
                    if (!accounts[0].idTokenClaims) {

                        const tryToRefreshIdTokenClaimsCounter  = sessionStorage.getItem('refreshIdTokenCounter');

                        if (tryToRefreshIdTokenClaimsCounter === null) {
                            sessionStorage.setItem('refreshIdTokenCounter', '1');

                            try {
                                await this.authService.instance.acquireTokenSilent({
                                    account: this.authService.instance.getAllAccounts()[0],
                                    scopes: []
                                });
                            } catch (error) {
                                this.log.error('An error occurred', error);
                            } finally {
                                window.location.reload();
                            }

                        }
                    }
                }

            });
    }

    ngOnDestroy(): void {
        this.destroying$.next(null);
        this.destroying$.complete();

        this.customerConfigLoadedSubscription.unsubscribe();
        this.hasUnreadMessageSubscription.unsubscribe();
        this.pushMessagesSubscription.unsubscribe();
        this.loggedInSubscription.unsubscribe();

        this.loggedInSubject$.complete();

        this.clearAlarmPolling();
    }

    login(userFlowRequest?: BaseAuthRequest): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this.destroying$)
            )
            .subscribe(() => {
                if (this.msalGuardConfig.authRequest) {
                    this.authService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest});
                } else {
                    this.authService.loginRedirect(userFlowRequest);
                }
            });
    }

    logout(): void {
        this.authService.logout();
        this.loggedInSubject$.next(false);
    }

    isLanguageAvailable(lang: string): boolean {
        return this.customerConfigService.isLanguageAvailable(lang);
    }

    changeLanguage(lang: string): void {
        this.currentLanguage = lang;
        this.translateUtils.setLanguage(lang);
    }

    toggleMobileMenu(): void {
        if (this.isMobile) {
            this.isExpanded = !this.isExpanded;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(_): void {
        this.innerWidth = window.innerWidth;
        this.isMobile = this.innerWidth < this.maxWidthAllowed;
    }

    getTransactionRootRoute(): Array<string> {
        return (this.rolesService.hasRoleCashier() || this.rolesService.hasRoleUser()) ?
            ['transactions'] : ['transactions', 'search'];
    }

    downloadInstructionsManual() {
        this.documentService.downloadInstructionsManualDocument().pipe().subscribe(response => {
            fileSaver.saveAs(response.data, response.fileName);
        });
    }

    private checkIfLoggedIn(): void {
        if (this.authService.instance.getAllAccounts().length > 0) {
            this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
            this.loggedInSubject$.next(true);

        } else {
            this.loggedInSubject$.next(false);
        }
    }

    private fetchHasAlarmOrAlert(): void {
        if (this.loggedIn && this.customerConfigService.isAlarmsEnabled() &&
            (this.rolesService.hasRoleAlarmManager() || this.rolesService.hasRoleAdmin())) {

            this.alarmService.hasActiveAlarm().pipe().subscribe(hasActiveAlarm => {
                this.isAlarmTriggering = hasActiveAlarm;
            });
            this.alertService.hasActiveAlert().pipe().subscribe(hasActiveAlert => {
                this.isAlertTriggering = hasActiveAlert;
            });
        }
    }

    private configureAlarmPolling(): void {
        if (!this.alarmRefreshInterval && this.customerConfigService.isAlarmsEnabled() &&
            (this.rolesService.hasRoleAlarmManager() || this.rolesService.hasRoleAdmin())) {

            this.alarmRefreshInterval = setInterval(_ => {
                this.fetchHasAlarmOrAlert();
            }, 60000);
        }
    }

    private clearAlarmPolling(): void {
        if (!!this.alarmRefreshInterval) {
            clearInterval(this.alarmRefreshInterval);
            this.alarmRefreshInterval = null;
        }
    }

    private managePushSubscription(): void {
        if (!this.env.firebase['apiKey'] || firebase.messaging?.isSupported()) {
            // We don't ask the permission if the browser does not support Push notification
            // Or if firebase is not configured
            return;
        }

        if (Notification.permission === 'granted') {
            if (this.loggedIn) {
                this.registerToken();

            } else {
                const email = localStorage.getItem(AppConstants.pushRegistrationEmailKey);
                if (!!email) {
                    this.registerToken();

                } else {
                    // Need to ask the permission to save the email
                    localStorage.setItem(AppConstants.pushPermissionAskedKey, 'false');
                    this.displayModalToAskForPushPermission();
                }
            }

        } else {
            // Ask the permission only if not asked once
            if (localStorage.getItem(AppConstants.pushPermissionAskedKey) !== 'true') {
                this.displayModalToAskForPushPermission();
            }
        }
    }

    private displayModalToAskForPushPermission(): void {
        if (localStorage.getItem(AppConstants.pushPermissionAskedKey) !== 'true') {

            const modal = this.modalService.open(PushNotificationModalComponent, {centered: true, windowClass: 'larger'});
            modal.result
                .then(result => {
                    if (result === ConfirmModalService.yes) {
                        localStorage.setItem(AppConstants.pushPermissionAskedKey, 'true');
                        this.requestNotificationBrowserPermission();

                    } else if (result === ConfirmModalService.no) {
                        localStorage.setItem(AppConstants.pushPermissionAskedKey, 'true');
                    }

                }, () => { /* catch the rejection */ });
        }
    }

    private requestNotificationBrowserPermission(): void {
        this.firebaseMessaging.requestPermission
            .subscribe(() => {
                    const existingToken = localStorage.getItem(AppConstants.pushRegistrationTokenKey);
                    if (!existingToken) {
                        // Display success message only the first time
                        this.notificationService.success({title: 'push.notifications.granted'});
                    }

                    this.registerToken();
                },
                error => {
                    if (!!error?.code && error.code === 'messaging/permission-blocked'){
                        this.notificationService.warning({
                            title: 'push.notifications.blocked.title',
                            message: 'push.notifications.blocked.message'});

                    } else {
                        this.notificationService.error({title: 'push.notifications.notGranted'});
                    }
                });
    }

    private registerToken(): void {
        if (Notification.permission === 'granted') {
            this.firebaseMessaging.getToken.subscribe(token => {
                if (!!token) {
                    const currentToken = localStorage.getItem(AppConstants.pushRegistrationTokenKey);

                    if (token !== currentToken) {
                        if (this.loggedIn) {
                            if (!!this.userEmail) {
                                localStorage.setItem(AppConstants.pushRegistrationEmailKey, this.userEmail);
                            }

                            this.pushRegistrationService.registerToken(token).pipe().subscribe(_ => {
                                localStorage.setItem(AppConstants.pushRegistrationTokenKey, token);

                                this.subscribeToPushMessages();
                            });

                        } else {
                            const email = localStorage.getItem(AppConstants.pushRegistrationEmailKey);
                            if (!!email && email !== "null") {
                                this.pushRegistrationService.registerTokenForVisitor(token, email).pipe().subscribe(_ => {
                                    localStorage.setItem(AppConstants.pushRegistrationTokenKey, token);

                                    this.subscribeToPushMessages();
                                });
                            }
                        }
                    } else {
                        this.subscribeToPushMessages();
                    }
                }
            }, error => {
                console.error(error);
            });
        }
    }

    private subscribeToPushMessages(): void {
        // Listen for Push messages
        this.pushMessagesSubscription = this.firebaseMessaging.messages.subscribe((payload: MessagePayload) => {

            if (!!payload && !!payload.data) {

                let notificationOptions = {
                    title: payload.data['CustomData.TitleFR'],
                    body: payload.data['CustomData.BodyFR']
                };

                switch (this.currentLanguage) {
                    case 'de':
                        notificationOptions.title = payload.data['CustomData.TitleDE'];
                        notificationOptions.body = payload.data['CustomData.BodyDE'];
                        break;
                    case 'it':
                        notificationOptions.title = payload.data['CustomData.TitleIT'];
                        notificationOptions.body = payload.data['CustomData.BodyIT'];
                        break;
                    case 'en':
                        notificationOptions.title = payload.data['CustomData.TitleEN'];
                        notificationOptions.body = payload.data['CustomData.BodyEN'];
                        break;
                }

                switch (payload.data['NotificationLevel']) {
                    case 'Warning':
                        this.notificationService.warning(notificationOptions);
                        break;
                    case 'Important':
                        this.notificationService.warning(notificationOptions);
                        break;
                    default:
                        this.notificationService.info(notificationOptions);
                        break;
                }

                this.communicationChannelConfigService.fetchHasUnreadMessage();
            }
        });
    }

    /**
     * Used to manage the frontend update when necessary (e.g. Breaking changes in API)
     */
    private manageAppUpdate(): void {
        this.updates.versionUpdates.subscribe(event => {
            if (event.type === 'VERSION_DETECTED') {
                this.updates.activateUpdate().then(() => {
                    if (confirm(this.translateService.instant('common.pwa.newAppAvailable'))) {
                        window.location.reload();
                    }
                });
            }
        });

        this.updates.unrecoverable.subscribe(event => {
            console.log(JSON.stringify(event));
            this.notificationService.warning({ title: 'common.pwa.unrecoverableState'});
            window.location.reload();
        });
    }
}
