import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BookingService,
    ServiceCategoryWithServicesDto,
    CraningBookingService,
    MyServiceDto, MyServicesNextEntryDto, MyServicesNextEntryType,
    ServiceConfigDto,
    ServiceConfigService,
    ServiceService,
    ServiceType,
    WifiSubscriptionService, OrderDto
} from '../_services/configuration-services';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {AppRoles} from '../app.constants';
import {NotificationsService} from '../_shared/notifications.service';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, interval, Subject, Subscription} from 'rxjs';
import {debounce, filter, map, takeUntil} from 'rxjs/operators';
import {faCircle, faArrowUpWideShort} from '@fortawesome/free-solid-svg-icons';
import {DatePipe} from '@angular/common';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {ServiceIconUrlBuilder} from '../_shared/service-icon-url-builder';
import {InteractionStatus} from '@azure/msal-browser';
import {DateUtils} from '../_shared/date-utils';
import {TranslateUtils} from '../_shared/translate-utils';
import {
    SortableData,
    SortableListModalComponent
} from '../_shared/_components/sortable-list-modal/sortable-list-modal.component';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class ServicesComponent implements OnInit, OnDestroy {

    private langChangeSubscription: Subscription;
    private readonly destroying$ = new Subject<void>();

    crtLang = TranslateUtils.defaultLanguage;
    AppRoles = AppRoles;
    buildIconUrl = ServiceIconUrlBuilder.buildIconUrl;

    dateFormat = DateUtils.dateFormat;
    timeFormat = DateUtils.timeWithoutSecondFormat;

    // Icons
    icons = {
        circle: faCircle,
        sort: faArrowUpWideShort
    }

    servicesByCategory: ServiceCategoryWithServicesDto[] = [];
    serviceConfigsMap: [string, ServiceConfigDto][]; // Map of service config sorted by translation
    serviceConfig: ServiceConfigDto | null;
    myServices: MyServiceDto[] = null;

    isLoggedIn = false;

    MyServicesNextEntryType = MyServicesNextEntryType;

    constructor(private readonly serviceService: ServiceService,
                private readonly serviceConfigService: ServiceConfigService,
                private readonly bookingService: BookingService,
                private readonly wifiSubscriptionService: WifiSubscriptionService,
                private readonly craningBookingService: CraningBookingService,
                private readonly modalService: NgbModal,
                private readonly notificationService: NotificationsService,
                private readonly translateService: TranslateService,
                private readonly broadcastService: MsalBroadcastService,
                private readonly router: Router,
                private readonly authService: MsalService,
                private readonly datePipe: DatePipe) {
    }

    async ngOnInit(): Promise<void> {
        if (this.router.url.startsWith('/state')) { // The page will be reload with /services
            await this.router.navigate(['../services']);
            return;
        }

        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange
            .subscribe(_ => {
                const newLang = this.translateService.currentLang;
                if (newLang !== this.crtLang) {
                    this.crtLang = newLang;
                    window.location.reload();
                }
            });

        this.servicesByCategory = await this.serviceService.getAllServicesByCategory().pipe(
            map((result) => result.filter(c => c.services.length > 0))
        ).toPromise();

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None || status === InteractionStatus.HandleRedirect),
                debounce(() => interval(200)),
                takeUntil(this.destroying$)
            )
            .subscribe(_ => {
                this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0 && this.authService.instance.getActiveAccount() !== null;

                // Skip refresh if myServices is already set because this code is called when
                // we left the page and we don't want to call the backend when we quit the page
                if (this.isLoggedIn && !this.myServices) {
                    this.fetchServices();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroying$.next(undefined);
        this.destroying$.complete();
        this.langChangeSubscription?.unsubscribe();

        this.modalService.dismissAll('destroy');
    }

    async createNewService(content): Promise<void> {
        await this.serviceConfigService.getAllServiceConfigs(true).toPromise().then(serviceConfigs => {
            // Sort by translations
            this.serviceConfigsMap = serviceConfigs.map(serviceConfig => {
                const translated = this.translateService.instant('services.serviceConfigs.' + serviceConfig.serviceType) as string;
                return [translated, serviceConfig];
            });
            this.serviceConfigsMap.sort((a, b) => a[0].localeCompare(b[0]));
            this.serviceConfig = this.serviceConfigsMap[0][1]; // Select the first element
        });

        const modal = this.modalService.open(content, {centered: true});
        modal.result
            .then(async value => {
                if (value === 'confirm') {
                    await this.router.navigate(['services/manage'], {state: {data: this.serviceConfig}});
                }
            }, () => { /* catch the rejection */ });
    }

    async orderServices(): Promise<void> {
        const sortableData: SortableData[] = [];
        const allServices = await firstValueFrom(this.serviceService.getAllServices());

        allServices.forEach(service => sortableData.push({
            id: service.serviceId,
            displayTextFR: service.nameFR,
            displayTextDE: service.nameDE,
            displayTextIT: service.nameIT,
            displayTextEN: service.nameEN
        }));

        const modal = this.modalService.open(SortableListModalComponent, {centered: true})
        modal.componentInstance.sortableData = sortableData;

        const result = await modal.result;

        if(result){
            await this.saveOrder(result);
            await this.fetchServices();
        }
    }

    public areStartAndEndDatesEquals(entry: MyServicesNextEntryDto): boolean {
        return DateUtils.equals(DateUtils.dateToNgbDateStruct(entry.startDate), DateUtils.dateToNgbDateStruct(entry.endDate));
    }

    public getNextEntryLabel(entry: MyServicesNextEntryDto): string {
        const hasTimeInDate = !entry.startTime && !entry.endTime;
        const sameDay = this.areStartAndEndDatesEquals(entry);

        const label = sameDay ? 'services.myNextData' : 'services.myNextDataMoreThanOneDay';

        let startTime;
        let endTime;
        if (hasTimeInDate) {
            startTime = this.datePipe.transform(entry.startDate, this.timeFormat);
            endTime = this.datePipe.transform(entry.endDate, this.timeFormat);
        } else {
            startTime = entry.startTime.substr(0, 5);
            endTime = entry.endTime.substr(0, 5);
        }

        return this.translateService.instant(label, {
            StartDate: this.datePipe.transform(entry.startDate, this.dateFormat),
            EndDate: this.datePipe.transform(entry.endDate, this.dateFormat),
            StartTime: startTime,
            EndTime: endTime
        });
    }

    private async fetchServices(): Promise<void> {
        this.myServices = await firstValueFrom(this.serviceService.getMyServices());
        this.manageMyServices(this.myServices);
    }

    private manageMyServices(myServices: MyServiceDto[]): void {
        myServices.forEach(s => {

            switch (s.serviceConfig.serviceType) {
                case ServiceType.Bookable:
                    this.bookingService.getMyNextBookingForService(s.serviceId).pipe().subscribe(booking => {
                        s.nextEntry = booking;
                    });
                    break;

                case ServiceType.CraningAndWinterizing:
                    this.craningBookingService.getMyNextCraningBookingForService(s.serviceId).pipe().subscribe(booking => {
                        s.nextEntry = booking;
                    });
                    break;

                case ServiceType.Wifi:
                    this.wifiSubscriptionService.getMyCurrentOrNextSubscription(s.serviceId).pipe().subscribe(subscription => {
                        s.nextEntry = subscription;
                    });
                    break;
            }
        });
    }

    private async saveOrder(serviceSort: SortableData[]): Promise<void> {
        const serviceOrder = [];

        serviceSort.forEach((value, index) => serviceOrder.push(new OrderDto({
            id: value.id,
            position: index
        })));

        await firstValueFrom(this.serviceService.orderServices(serviceOrder));
        this.notificationService.success({ title: 'common.notifications.orderChangeSuccess' });
    }
}
