
<div class="content-container bg-white">

    <h1>{{ (!!informationId ? 'communicationChannel.information.edition.modifyTitle' : 'communicationChannel.information.edition.addTitle') |translate }}</h1>

    <div class="btn-bar">
        <button type="button" class="btn btn-outline-secondary"
                (click)="back()"
                translate="common.back">
        </button>

        <button type="submit" class="btn btn-outline-danger" *ngIf="!!informationId"
                (click)="deleteInformation()"
                translate="common.delete">
        </button>
    </div>

    <form name="informationForm" [formGroup]="informationForm">

        <div class="row">
            <div class="col-12 col-lg-6">
                <label class="form-label" for="titleFR">{{'communicationChannel.information.edition.form.titleFR' | translate}}*</label>
                <input class="form-control" id="titleFR" type="text" maxlength="50"
                       [formControl]="titleFR"
                       placeholder="{{ 'communicationChannel.information.edition.placeholder.titleFR' | translate }}">
                <app-form-error [control]="titleFR"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="titleDE">{{'communicationChannel.information.edition.form.titleDE' | translate}}</label>
                <input class="form-control" id="titleDE" type="text" maxlength="50"
                       [formControl]="titleDE"
                       placeholder="{{ 'communicationChannel.information.edition.placeholder.titleDE' | translate }}">
                <app-form-error [control]="titleDE"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="titleIT">{{'communicationChannel.information.edition.form.titleIT' | translate}}</label>
                <input class="form-control" id="titleIT" type="text" maxlength="50"
                       [formControl]="titleIT"
                       placeholder="{{ 'communicationChannel.information.edition.placeholder.titleIT' | translate }}">
                <app-form-error [control]="titleIT"></app-form-error>
            </div>

            <div class="col-12 col-lg-6" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="description">{{'communicationChannel.information.edition.form.titleEN' | translate}}</label>
                <input class="form-control" id="description" type="text" maxlength="50"
                       [formControl]="titleEN"
                       placeholder="{{ 'communicationChannel.information.edition.placeholder.titleEN' | translate }}">
                <app-form-error [control]="titleEN"></app-form-error>
            </div>

            <div class="col-12">
                <label class="form-label" for="contentFR">{{'communicationChannel.information.edition.form.contentFR' | translate}}*</label>
                <textarea class="form-control" id="contentFR" name="contentFR"
                          maxlength="2500"
                          rows="5"
                          [formControl]="contentFR"
                          placeholder="{{ 'communicationChannel.information.edition.placeholder.contentFR' | translate }}"></textarea>
                <app-form-error [control]="contentFR"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('de')">
                <label class="form-label" for="contentDE">{{'communicationChannel.information.edition.form.contentDE' | translate}}</label>
                <textarea class="form-control" id="contentDE" name="contentDE"
                          maxlength="2500"
                          rows="5"
                          [formControl]="contentDE"
                          placeholder="{{ 'communicationChannel.information.edition.placeholder.contentDE' | translate }}"></textarea>
                <app-form-error [control]="contentDE"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
                <label class="form-label" for="contentIT">{{'communicationChannel.information.edition.form.contentIT' | translate}}</label>
                <textarea class="form-control" id="contentIT" name="contentIT"
                          maxlength="2500"
                          rows="5"
                          [formControl]="contentIT"
                          placeholder="{{ 'communicationChannel.information.edition.placeholder.contentIT' | translate }}"></textarea>
                <app-form-error [control]="contentIT"></app-form-error>
            </div>

            <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
                <label class="form-label" for="contentEN">{{'communicationChannel.information.edition.form.contentEN' | translate}}</label>
                <textarea class="form-control" id="contentEN" name="contentEN"
                          maxlength="2500"
                          rows="5"
                          [formControl]="contentEN"
                          placeholder="{{ 'communicationChannel.information.edition.placeholder.contentEN' | translate }}"></textarea>
                <app-form-error [control]="contentEN"></app-form-error>
            </div>

        </div>

        <hr>
        <div class="mt-4">

            <h4 translate="communicationChannel.information.edition.form.roles"></h4>
            <app-role-management [enablePublicAccess]="false"
                                 [enableBaseRoles]="true"
                                 [enableCustomRoles]="true"
                                 [userOnly]="true"
                                 [hideCustomIfUserSelected]="true"
                                 [roleForm]="rolesFormArray"
                                 [requiredFields]="false"></app-role-management>

            <div class="btn-bar">
                <button type="button" class="btn btn-outline-secondary"
                        (click)="back()"
                        translate="common.back">
                </button>

                <button type="submit" class="btn btn-primary"
                        [disabled]="informationForm.invalid || informationForm.pristine"
                        (click)="saveInformation()" translate="common.save">
                </button>
            </div>
        </div>

        <!-- Illustration, Documents & Publication -->
        <ng-container *ngIf="!!informationId">
            <hr>
            <h4 translate="communicationChannel.information.edition.form.illustration"></h4>

            <!-- Illustration -->
            <form name="illustrationForm" [formGroup]="illustrationForm">

                <div class="row">

                    <div class="col-12 col-lg-6">
                        <label class="form-label" for="file">{{ 'communicationChannel.information.edition.form.illustration' | translate }}</label>
                        <p translate="common.imageMaxSize" [translateParams]="{ImageMaxSize: imageMaxSizeInKB}"></p>

                        <input class="form-control" id="file" type="file"
                               accept="{{ supportedImage }}"
                               (change)="fileSelected($event.target.files)"
                               [formControl]="file">
                        <app-form-error [control]="file"></app-form-error>
                    </div>

                    <div class="col-12 col-lg-6">
                        <img class="img-thumbnail" alt="Illustration" *ngIf="illustration.value"
                             src="{{ illustration.value }}">
                    </div>
                </div>

                <div class="btn-bar">
                    <button type="submit" class="btn btn-primary"
                            [disabled]="illustrationForm.invalid || illustrationForm.pristine"
                            (click)="saveIllustration()"
                            translate="common.saveIllustration">
                    </button>
                </div>
            </form>

            <hr>

            <!-- Documents -->
            <div class="row">
                <document-management-component [documentManagementType]="DocumentManagementTypeEnum.Information"
                                               [id]="informationId"></document-management-component>
            </div>

            <hr>

            <!-- Banner -->
            <h4>{{ 'communicationChannel.information.edition.form.banner' | translate }}</h4>

            <form class="mt-4" [formGroup]="bannerForm">
                <div class="row">

                    <div class="col form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="isBannerActive" formControlName="isBannerActive">
                        <label class="form-check-label" for="isBannerActive">{{ 'communicationChannel.information.edition.form.activateBanner' | translate }}</label>
                    </div>

                    @if (isBannerActive.value) {
                        <div class="col-12">
                            <label class="form-label" for="bannerDescriptionFR">{{ 'communicationChannel.information.edition.form.descriptionFR' | translate }}*</label>
                            <input type="text" class="form-control" id="bannerDescriptionFR" formControlName="bannerDescriptionFR">
                            <app-form-error [control]="bannerDescriptionFR"></app-form-error>
                        </div>

                        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('de')">
                            <label class="form-label" for="bannerDescriptionDE">{{ 'communicationChannel.information.edition.form.descriptionDE' | translate }}</label>
                            <input type="text" class="form-control" id="bannerDescriptionDE" formControlName="bannerDescriptionDE">
                            <app-form-error [control]="bannerDescriptionDE"></app-form-error>
                        </div>

                        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('en')">
                            <label class="form-label" for="bannerDescriptionEN">{{ 'communicationChannel.information.edition.form.descriptionEN' | translate }}</label>
                            <input type="text" class="form-control" id="bannerDescriptionEN" formControlName="bannerDescriptionEN">
                            <app-form-error [control]="bannerDescriptionEN"></app-form-error>
                        </div>

                        <div class="col-12" *ngIf="customerConfigService.isLanguageAvailable('it')">
                            <label class="form-label" for="bannerDescriptionIT">{{ 'communicationChannel.information.edition.form.descriptionIT' | translate }}</label>
                            <input type="text" class="form-control" id="bannerDescriptionIT" formControlName="bannerDescriptionIT">
                            <app-form-error [control]="bannerDescriptionIT"></app-form-error>
                        </div>

                        <div class="col-12">
                            <label class="form-label" for="bannerInformationLevel">{{ 'communicationChannel.information.edition.form.bannerInformationLevel' | translate }}*</label>
                            <select class="form-select" id="bannerInformationLevel" formControlName="bannerInformationLevel">
                                <option *ngFor="let infoLevel of bannerInformationLevels" [value]="infoLevel">{{ 'communicationChannel.information.edition.enums.bannerInformationLevel.' + toLower(infoLevel) | translate}}</option>
                            </select>
                        </div>
                    }
                    <div class="btn-bar">
                        <button type="submit" class="btn btn-primary"
                                [disabled]="bannerForm.pristine || bannerForm.invalid"
                                (click)="saveBanner()"
                                translate="common.save">
                        </button>
                    </div>
                </div>
            </form>

            <hr>

            <!-- Publication -->
            <h4 translate="communicationChannel.information.edition.form.publication"></h4>

            <form name="publicationForm" [formGroup]="publicationForm">

                <div class="row" #publicationSection>

                    <div class="col-12 col-lg-6 col-xl-4">
                        <app-date-period-picker [periodControl]="periodForm"
                                                [isFromRequired]="true"
                                                inputLabel="communicationChannel.information.edition.form.publicationPeriod">
                        </app-date-period-picker>
                    </div>
                </div>

                <p class="text-error col-12" *ngIf="usersAlreadyNotified" translate="communicationChannel.publication.messages.alreadyNotified"></p>
                <p class="text-error col-12" *ngIf="!usersAlreadyNotified" translate="communicationChannel.publication.messages.noAlreadyNotified"></p>

                <div class="row">
                    <div class="col-12">
                        <label class="form-label">{{ 'communicationChannel.information.edition.form.mailNotification' | translate }}*</label>

                        <div class="form-check form-switch">
                            <input class="form-check-input" id="mailNotification" type="checkbox" name="mailNotification"
                                   [formControl]="mailNotificationEnabled" />
                            <label class="form-check-label" for="mailNotification">{{ (this.mailNotificationEnabled.value ? 'common.yes' : 'common.no') | translate }}</label>
                        </div>
                    </div>
                </div>

                <div class="btn-bar">
                    <button type="button" class="btn btn-outline-secondary"
                            (click)="back()"
                            translate="common.back">
                    </button>

                    <button type="submit" class="btn btn-primary"
                            [disabled]="publicationForm.invalid || publicationForm.pristine"
                            (click)="publishInformation()"
                            translate="common.publish">
                    </button>
                </div>

            </form>

        </ng-container>

    </form>

</div>
