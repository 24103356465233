import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderDto, SurveyLightDto, SurveyService} from '../../../_services/configuration-services';
import {Subscription} from 'rxjs';
import {TranslateUtils} from '../../../_shared/translate-utils';
import {DateUtils} from '../../../_shared/date-utils';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ConfirmModalService} from "../../../_shared/_components/confirm-modal/confirm-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationsService} from "../../../_shared/notifications.service";
import {
    SortableData,
    SortableListModalComponent
} from "../../../_shared/_components/sortable-list-modal/sortable-list-modal.component";
import {Location} from '@angular/common';

@Component({
    selector: 'app-surveys-management',
    templateUrl: './surveys-management.component.html'
})
export class SurveysManagementComponent implements OnInit, OnDestroy {

    surveys: SurveyLightDto[] = [];

    private langChangeSubscription: Subscription;
    crtLang = TranslateUtils.defaultLanguage;

    // Formats
    formats = {
        date: DateUtils.dateFormat,
    };

    // Icons
    icons = {
        trash: faTrash,
        pen: faPen
    };

    constructor(private readonly surveyService: SurveyService,
                private readonly translateService: TranslateService,
                private readonly modalService: NgbModal,
                private readonly confirmService: ConfirmModalService,
                private readonly location: Location,
                private readonly notificationsService: NotificationsService
    ) {
    }

    ngOnInit(): void {
        this.crtLang = this.translateService.currentLang;
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.crtLang = event.lang;
            });
        this.fetchSurveys();
    }

    ngOnDestroy(): void {
        this.langChangeSubscription.unsubscribe();
        this.modalService.dismissAll('destroy');
    }

    fetchSurveys(): void {
        this.surveyService.getAll().pipe().subscribe(
            res => this.surveys = res,
            _ => this.surveys = []);
    }


    deleteSurvey(id: string): void {
        this.confirmService.confirm({titleKey: 'common.confirmModal.title.delete'}).then(result => {
            if (result === ConfirmModalService.yes) {
                this.surveyService.deleteSurvey(id).pipe().subscribe(res => {
                    this.notificationsService.success({title: 'communicationChannel.survey.notification.deleteSuccess'});
                    this.fetchSurveys();
                });
            }
        });
    }


    orderSurveys(): void {
        const data: SortableData[] = [];
        this.surveys.forEach(val => data.push({
            id: val.id,
            displayTextFR: val.titleFR,
            displayTextDE: val.titleDE,
            displayTextIT: val.titleIT,
            displayTextEN: val.titleEN
        }));

        const modal = this.modalService.open(SortableListModalComponent, {centered: true});
        modal.componentInstance.sortableData = data;
        modal.result
            .then(value => {
                if (value) {
                    this.saveOrder(value);
                }
            }, () => { /* catch the rejection */ });

    }

    private saveOrder(surveysSort: SortableData[]): void {
        const surveyToSave = [];
        surveysSort.forEach((value, index) => surveyToSave.push(new OrderDto({
            id: value.id,
            position: index
        })));

        this.surveyService.orderSurveys(surveyToSave).pipe().subscribe(_ => {
            this.notificationsService.success({title: 'common.notifications.orderChangeSuccess'});
            this.fetchSurveys();
        });
    }

    back() {
        this.location.back();
    }
}
